import React, { Component } from 'react';
import Title from './Title';
import {FaCocktail,FaHiking,FaShuttleVan} from "react-icons/fa";

export default class Services extends Component {
    state={
        service:[
            {
                icon:<FaCocktail/>,
                title:"Clubhouse",
                info:'Special offfers in the houses'

            },
            {
                icon:<FaShuttleVan/>,
                title:"ShuttleVan",
                info:'Provides shuttle van for travel'

            },
            {
                icon:<FaHiking/>,
                title:"Hiking & Boating",
                info:'Our crew helps for boating & hiking'

            },
            {
                icon:<FaCocktail/>,
                title:"Food & Beverage",
                info:'Special authentic food and world class beverages'

            }
        ]
    }
    
    render() {
        return (
            <section className="services">
               <Title title="Services"/>
            <div className="services-center">
                {this.state.service.map((item,index)=>
                {
                    return(<article key={index} className="service">
                    <span>{item.icon}</span>
                    <h6>{item.title}</h6>
                    <p>{item.info}</p>
                    </article>
                    );
                })}
            </div>
            </section>
        )
    }
}

