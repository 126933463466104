import React from 'react'
import Hero from '../components/Hero.js'
import {Link} from 'react-router-dom'
import Banner from '../components/Banner'
import RoomsContainer from '../components/RoomsContainer.js'
import Footer from '../components/Footer'

export default function Rooms() {
    return (
        <>
        <Hero hero="roomsHero">
              <Banner title="Our Apartments">
              <Link to="/" class="btn-primary">Return home</Link>
          </Banner>
        </Hero>
        <RoomsContainer/>
        <Footer/>
        </>
    )
}
