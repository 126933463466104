
import React from 'react'
import Hero from '../components/Hero.js'
import Banner from '../components/Banner'
import {Link} from 'react-router-dom'
import Services from '../components/Services'
import FeaturedRooms from '../components/FeaturedRooms'
import Footer from '../components/Footer'
import styledHero  from '../components/StyledHero'
export default function Home() {
    return (
    <React.Fragment>
      <Hero>
          <Banner title="Luxurious Houses" subtitle="Apartments">
              <Link to="/rooms" class="btn-primary">Apartments</Link>
          </Banner>
      </Hero>
      <Services/>
      <FeaturedRooms/>
      <Footer/>
    </React.Fragment>
    )
}
