import room1 from "./images/room-7.jpg";
import room2 from "./images/room-8.jpg";
import room3 from  "./images/room-5.jpg";
import room4 from "./images/details-4.jpeg";
import img1 from "./images/room-1.jpeg";
import img2 from "./images/room-2.jpeg";
import img3 from "./images/room-3.jpg";
import img4 from "./images/room-4.jpeg";
import img5 from "./images/room-5.jpg";
import img6 from "./images/room-6.jpg";
import img7 from "./images/details-1.jpg";
import img8 from "./images/room-8.jpg";
import img9 from "./images/room-9.jpeg";
import img10 from "./images/room-10.jpeg";
import img11 from "./images/room-11.jpg";
import img12 from "./images/room-12.jpeg";

export default [
  {
    sys: {
      id: "1"
    },
    fields: {
      name: "single economy",
      slug: "single-economy",
      type: "single",
      price: 150,
      size: 230,
      capacity: 1,
      pets: false,
      breakfast: false,
      featured: false,
      description:
        "A spacious beachfront room featuring one queen-sized beds and an oversized private balcony with beautiful views of the pool and tropical landscaping. Enjoy the convenience of home with 49-inch TV, a full-size refrigerator, microwave, and K-Cup coffee brewer. ",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities"
      
      ],
      images: [
        {
          fields: {
            file: {
              url: img12
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "2"
    },
    fields: {
      name: "single basic",
      slug: "single-basic",
      type: "single",
      price: 250,
      size: 300,
      capacity: 1,
      pets: false,
      breakfast: false,
      featured: false,
      description:
        "A spacious beachfront room featuring two queen-sized beds, beautiful views of the Gulf of Mexico and tropical landscaping from a private balcony. Enjoy the convenience of home with a 49-inch TV, full-size refrigerator, microwave, and K-Cup Keurig coffee brewer. Floors 4-7",
      extras: [
        
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities"
      
      ],
      images: [
        {
          fields: {
            file: {
              url: img2
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "3"
    },
    fields: {
      name: "single standard",
      slug: "single-standard",
      type: "single",
      price: 250,
      size: 300,
      capacity: 1,
      pets: true,
      breakfast: false,
      featured: false,
      description:
        "A spacious beachfront room featuring one queen-sized beds and an oversized private balcony with beautiful views of the pool and tropical landscaping. Enjoy the convenience of home with 49-inch TV, a full-size refrigerator, microwave, and K-Cup coffee brewer. ",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities"
      
        
      ],
      images: [
        {
          fields: {
            file: {
              url: img3
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "4"
    },
    fields: {
      name: "single deluxe",
      slug: "single-deluxe",
      type: "single",
      price: 300,
      size: 400,
      capacity: 1,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "A spacious beachfront room featuring one queen-sized beds and an oversized private balcony with beautiful views of the pool and tropical landscaping. Enjoy the convenience of home with 49-inch TV, a full-size refrigerator, microwave, and K-Cup coffee brewer. ",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities",      
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        
      ],
      images: [
        {
          fields: {
            file: {
              url: img4
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "5"
    },
    fields: {
      name: "double economy",
      slug: "double-economy",
      type: "double",
      price: 200,
      size: 300,
      capacity: 2,
      pets: false,
      breakfast: false,
      featured: false,
      description:
        "A spacious beachfront room featuring one queen-sized beds and an oversized private balcony with beautiful views of the pool and tropical landscaping. Enjoy the convenience of home with 49-inch TV, a full-size refrigerator, microwave, and K-Cup coffee brewer. ",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security"
        
      ],
      images: [
        {
          fields: {
            file: {
              url: img5
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "6"
    },
    fields: {
      name: "double basic",
      slug: "double-basic",
      type: "double",
      price: 250,
      size: 350,
      capacity: 2,
      pets: false,
      breakfast: false,
      featured: false,
      description:
        "A spacious beachfront room featuring two queen-sized beds, beautiful views of the Gulf of Mexico and tropical landscaping from a private balcony. Enjoy the convenience of home with a 49-inch TV, full-size refrigerator, microwave, and K-Cup Keurig coffee brewer.",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security"
      ],
      images: [
        {
          fields: {
            file: {
              url: img6
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "7"
    },
    fields: {
      name: "double standard",
      slug: "double-standard",
      type: "double",
      price: 300,
      size: 400,
      capacity: 2,
      pets: true,
      breakfast: false,
      featured: false,
      description:
        "A spacious beachfront room featuring two queen-sized beds, beautiful views of the Gulf of Mexico and tropical landscaping from a private balcony. Enjoy the convenience of home with a 49-inch TV, full-size refrigerator, microwave, and K-Cup Keurig coffee brewer.",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security"
      ],
      images: [
        {
          fields: {
            file: {
              url: img7
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "8"
    },
    fields: {
      name: "double deluxe",
      slug: "double-deluxe",
      type: "double",
      price: 400,
      size: 500,
      capacity: 2,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "A spacious beachfront room featuring two queen-sized beds, beautiful views of the Gulf of Mexico and tropical landscaping from a private balcony. Enjoy the convenience of home with a 49-inch TV, full-size refrigerator, microwave, and K-Cup Keurig coffee brewer.",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security"
      ],
      images: [
        {
          fields: {
            file: {
              url: img8
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "9"
    },
    fields: {
      name: "family economy",
      slug: "family-economy",
      type: "family",
      price: 300,
      size: 500,
      capacity: 3,
      pets: false,
      breakfast: false,
      featured: false,
      description:
        " Family Rooms at the PVNS Resort are a great way for families with more than two kids to stay together! These rooms offer larger families the option of all staying in the same room instead of paying for two rooms so you can stay and play with us at The Resort longer!",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security",
        "Private balcony sit out"
      ],
      images: [
        {
          fields: {
            file: {
              url: img9
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "10"
    },
    fields: {
      name: "family basic",
      slug: "family-basic",
      type: "family",
      price: 350,
      size: 550,
      capacity: 4,
      pets: false,
      breakfast: false,
      featured: false,
      description:
        "Family Rooms at the PVNS Resort are a great way for families with more than two kids to stay together! These rooms offer larger families the option of all staying in the same room instead of paying for two rooms so you can stay and play with us at The Resort longer!",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security",
        "Private balcony sit out"
      ],
      images: [
        {
          fields: {
            file: {
              url: img10
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "11"
    },
    fields: {
      name: "family standard",
      slug: "family-standard",
      type: "family",
      price: 500,
      size: 650,
      capacity: 5,
      pets: true,
      breakfast: false,
      featured: false,
      description:
      "Family Rooms at the PVNS Resort are a great way for families with more than two kids to stay together! These rooms offer larger families the option of all staying in the same room instead of paying for two rooms so you can stay and play with us at The Resort longer!",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security",
        "Private balcony sit out"
      ],
      images: [
        {
          fields: {
            file: {
              url: img11
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "12"
    },
    fields: {
      name: "family deluxe",
      slug: "family-deluxe",
      type: "family",
      price: 500,
      size: 700,
      capacity: 6,
      pets: true,
      breakfast: true,
      featured: true,
      description:
      "Family Rooms at the PVNS Resort are a great way for families with more than two kids to stay together! These rooms offer larger families the option of all staying in the same room instead of paying for two rooms so you can stay and play with us at The Resort longer!",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security",
        "Private balcony sit out"
      ],
      images: [
        {
          fields: {
            file: {
              url: img12
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "13"
    },
    fields: {
      name: "presidential",
      slug: "presidential-room",
      type: "presidential",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "ont 2 room suite on the top floor of the Resort features a coastal style decor, a private bedroom, living or dining area and 2 gulf-front balconies that offer breathtaking views of the emerald blue-green waters of the gulf and Panama City Beach’s world-famous sunsets. Kick back, sleep deep and wake up refreshed on the plush king bed. The suite also features a full sized refrigerator and microwave as well as a Kuerig K-Cup machine so you can take in the scenery with a fresh cup of coffee.",
      extras: [
        "Wi-Fi",
        "Internet",
        "Comfortable beds",
        "Laundry service (Regular/Express)",
        "24 hrs Hot & Cold Water Facilities",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security",
        "Private balcony sit out",
        "Mini-Bar(on request)"
      ],
      images: [
        {
          fields: {
            file: {
              url: room1
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  }
];