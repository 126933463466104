import React from 'react'
import Hero from '../components/Hero.js'
import Banner from '../components/Banner.js'
import {Link} from 'react-router-dom'

export default function Error() {
    return (
        <hero>
              <Banner title="404" subtitle="Page not found">
              <Link to="/" class="btn-primary">Return home</Link>
          </Banner>
        </hero>

    )
}
